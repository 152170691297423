export const ERROR_REQUIRED = 'Required'
export const ERROR_DUPLICATE = 'Duplicate'

export const NAME_FIELD = 'dictionaryName'
export const NEW_GROUP_NAME_FIELD = 'newGroupName'
export const EXISTING_GROUP_NAME_FIELD = 'existingGroupName'

export const DOMAIN_FIELD = 'domain'
export const RANGE_FIELD = 'range'
export const TYPE_FIELD = 'dataType'
export const DESCRIPTION_FIELD = 'description'
