import React from 'react'
import { shape, arrayOf, string } from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media, SIZE_LG, SIZE_SM, FONT_SIZE_LG, GRAY_X11, SNOW, SIZE_XSM, GRANITE_GRAY, CYAN } from '../../styles'

import Accordion, { CN_ACCORDION_HEADER } from '../../components/Accordion/component'

const Wrap = styled.div`
  grid-area: sidebar;
  padding: 5.2rem 0 ${SIZE_LG};
  background-color: #444;
  box-shadow: inset 0 30px 20px -30px #00000033;
  font-size: ${FONT_SIZE_LG};
  transition: padding 0.1s ease-out;

  ${media.sm`
    padding: 8.4rem 0 ${SIZE_LG};
  `};
`
const UngroupedItems = styled.div`
  margin-bottom: ${SIZE_LG};
  padding-bottom: ${SIZE_LG};
`
const Title = styled.div`
  margin-bottom: ${SIZE_SM};
  padding: 0 ${SIZE_LG};
  color: ${GRANITE_GRAY};
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${FONT_SIZE_LG};
`
const AccordionStyled = styled(Accordion)`
  .${CN_ACCORDION_HEADER} {
    padding: ${SIZE_XSM} ${SIZE_LG};
  };
`
const SidebarItem = styled.div`
`
const SidebarLink = styled(Link)`
  display: block;
  padding: ${SIZE_XSM} ${SIZE_LG};
  color: ${GRAY_X11};

  ${props => props.isActive && `
    color: ${CYAN};
    font-weight: bold;
  `}; 
  
  &:hover {
    color: ${props => props.isActive ? CYAN : SNOW};
  };
`

const Sidebar = ({ activeDictionaryName, dictionaries }) => {
  const groupNames =
    [...new Set(dictionaries.map(dictionary => dictionary.dictionaryGroup))].filter(group => group)
  const dictionariesUngrouped = dictionaries.filter((dictionary) => !dictionary.dictionaryGroup)
  return (
    <Wrap>
      <UngroupedItems>
        <Title>ungrouped</Title>
        {dictionariesUngrouped.map(({ dictionaryName }) => (
          <SidebarItem
            key={dictionaryName}
          >
            <SidebarLink
              isActive={dictionaryName === activeDictionaryName}
              to={dictionaryName}
            >
              {dictionaryName}
            </SidebarLink>
          </SidebarItem>
        ))}
      </UngroupedItems>

      <Title>grouped</Title>
      {groupNames.map((groupName) => {
        const byGroup = dictionaries.filter(d => d.dictionaryGroup === groupName)
        return (
          <SidebarItem key={groupName}>
            <AccordionStyled
              name={groupName}
              content={byGroup.map(({ id, dictionaryName }) => (
                <SidebarLink
                  isActive={dictionaryName === activeDictionaryName}
                  key={id}
                  to={dictionaryName}
                >
                  {dictionaryName}
                </SidebarLink>
              ))}
            />
          </SidebarItem>
        )
      })}
    </Wrap>
  )
}

Sidebar.propTypes = {
  activeDictionaryName: string,
  dictionaries: arrayOf(shape({})).isRequired,
}

Sidebar.defaultProps = {
  activeDictionaryName: undefined,
}

export default Sidebar
