import React from 'react'
import { bool, string, func, node, shape } from 'prop-types'
import styled from 'styled-components'
import { SIZE_XLG, WHITE, SUNSET_ORANGE, ISABELLINE, CYAN } from '../../styles'
import { PROP_ASYNC_STATUS } from '../../constants'
import { ErrorFloat, CN_ERROR_FLOAT_SVG } from '../Error'
import { SpinnerLoader } from '../Loader'

const Wrap = styled.button`
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 1.8rem;
  color: ${WHITE};
  cursor: pointer;

  ${props => props.isStyled && `
    padding: 0 ${SIZE_XLG};
    height: 3.6rem;
    background: ${props.isError ? SUNSET_ORANGE : CYAN};

    &:hover {
      ${props => !props.isError && `background-color: ${SUNSET_ORANGE}`};
    };
  `};

  &:disabled {
    ${props => props.isStyled && 'background: #636363'};
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  };
`

const ErrorFloatStyled = styled(ErrorFloat)`
  .${CN_ERROR_FLOAT_SVG} {
    fill: ${ISABELLINE};
  };
`

const Button = ({ isStyled, isDisabled, type, asyncStatus, onClick, children, className }) => {
  const { isBusy, isError } = asyncStatus

  return (
    <Wrap
      isStyled={isStyled}
      isError={isError}
      disabled={isDisabled}
      type={type}
      tabIndex={-1}
      onClick={onClick}
      className={className}
    >
      {!isBusy && !isError && children}
      <SpinnerLoader
        isStatic
        asyncStatus={asyncStatus}
        size={2}
      />
      <ErrorFloatStyled
        asyncStatus={asyncStatus}
        tooltipPosition="left"
      />
    </Wrap>
  )
}

Button.propTypes = {
  isStyled: bool,
  children: node.isRequired,
  isDisabled: bool,
  type: string,
  onClick: func,
  asyncStatus: shape(PROP_ASYNC_STATUS),
  className: string,
}

Button.defaultProps = {
  isStyled: false,
  isDisabled: false,
  type: 'button',
  onClick() {},
  asyncStatus: undefined,
  className: '',
}

export default Button
