import React from 'react'
import { bool, string, shape } from 'prop-types'
import styled from 'styled-components'
import { SIZE_XSM, FONT_SIZE___SM, VERY_LIGHT_TANGELO, SUNSET_ORANGE } from '../../../styles'
import { PROP_ASYNC_STATUS, SHAPE_ASYNC_STATUS_INITIAL } from '../../../constants'

export const CN_ERROR_FIELD = 'error-field'

const Wrap = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: ${SIZE_XSM};
  font-size: ${FONT_SIZE___SM};
  color: ${props => props.isInfoVariant ? VERY_LIGHT_TANGELO : SUNSET_ORANGE};
`

const FieldError = ({ isInfoVariant, infoMessage, fieldStatus, asyncStatus, className }) => {
  const { isError, errorMessage } = fieldStatus || asyncStatus

  return (
    (!isError && !isInfoVariant) ? null :
    <Wrap isErrorVariant={!isInfoVariant} isInfoVariant={isInfoVariant} className={`${className} ${CN_ERROR_FIELD}`}>
      {errorMessage || infoMessage}
    </Wrap>
  )
}

FieldError.propTypes = {
  isInfoVariant: bool,
  infoMessage: string,
  fieldStatus: shape({ isError: bool, errorMessage: string }),
  asyncStatus: shape(PROP_ASYNC_STATUS),
  className: string,
}

FieldError.defaultProps = {
  isInfoVariant: false,
  infoMessage: undefined,
  fieldStatus: {},
  asyncStatus: SHAPE_ASYNC_STATUS_INITIAL,
  className: undefined,
}

export default FieldError
