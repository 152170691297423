import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import home from '../../pages/Home/reducer'

export default combineReducers({
  home,
  
  form: formReducer,
})
