import React, { useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { media, BOX_SHADOW_LIGHT, WHITE, SIZE_XLG } from '../styles'
import reset from '../styles/reset'
import { homePathSegment } from './Root/paths'

import { THEME_NORMAL } from '../constants'
import Header from './Header/container'
import Sidebar from './Sidebar/container'
import Home from '../pages/Home/container'

const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    font-size: ${props => props.activeTheme === 'normal' ? '62.5%' : props.activeTheme === 'compact' ? '50%' : '70%'};
  };
`

const PageWrap = styled.div`
  display: grid;
  grid-template:
    "header header" 6rem
    "sidebar main" auto
    / 16rem auto;
  height: 100vh;
`
const PageSpacer = styled.div`
  grid-area: main;
  margin: 0 auto;
  padding: ${SIZE_XLG} 4.2rem;
  width: 100%;
  max-width: 92rem;
  transition: padding 0.1s ease-out, width 0.1s ease-out;

  ${media.sm`
    padding: 6rem 8.2rem;
  `};
`
const ContentWrap = styled.div`
  position: relative;
  padding: 2.8rem 0;
  height: 100%;
  background-color: ${WHITE};
  box-shadow: ${BOX_SHADOW_LIGHT};
`

const Application = () => {
  const [activeTheme, setActiveTheme] = useState(THEME_NORMAL)

  return (
    <Router>
      <GlobalStyle activeTheme={activeTheme} />
      <PageWrap>
        <Header activeTheme={activeTheme} setActiveTheme={setActiveTheme} />
        <Sidebar />
        <PageSpacer>
          <ContentWrap>
            <Route path={homePathSegment()} component={Home} />
          </ContentWrap>
        </PageSpacer>
      </PageWrap>
    </Router>
  )
}

export default Application
