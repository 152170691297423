import styled from 'styled-components'
import { FONT_SIZE__SM, SUNSET_ORANGE, VERY_LIGHT_TANGELO } from '../../../styles'

export const STYLES_PLACEHOLDER_ACTIVE = `
  top: 2px;
  font-size: ${FONT_SIZE__SM};
  transform: translateY(0);
`

export default styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  color: ${props => props.isError ? SUNSET_ORANGE : 'rgba(0, 0, 0, 0.3)'};
  pointer-events: none;
  transform: translateY(-50%);
  transition: top 0.1s ease-out, transform 0.1s ease-out, font-size 0.1s ease-out;

  ${props => props.hasValue && STYLES_PLACEHOLDER_ACTIVE};
  ${props => !props.isError && props.isInfo && `color: ${VERY_LIGHT_TANGELO};`};
`
