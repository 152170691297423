import { css } from 'styled-components'

// font-size
export const FONT_SIZE___SM = '0.9rem'
export const FONT_SIZE__SM = '1rem'
export const FONT_SIZE_SM = '1.1rem'
export const FONT_SIZE_NORMAL = '1.2rem'
export const FONT_SIZE_LG = '1.4rem'
export const FONT_SIZE__LG = '1.6rem'


// units
export const SIZE_XSM_UNIT = 0.4
export const SIZE_SM_UNIT = 0.8
export const SIZE_MD_UNIT = 1.2
export const SIZE_LG_UNIT = 1.6
export const SIZE_XLG_UNIT = 2.4

export const SIZE_XSM = `${SIZE_XSM_UNIT}rem`
export const SIZE_SM = `${SIZE_SM_UNIT}rem`
export const SIZE_MD = `${SIZE_MD_UNIT}rem`
export const SIZE_LG = `${SIZE_LG_UNIT}rem`
export const SIZE_XLG = `${SIZE_XLG_UNIT}rem`


// colors
export const CYAN = '#00b6ea'

export const PASTEL_GREEN = '#7bd37c'
export const SUNSET_ORANGE = '#ff5d5d'
export const RED_ORANGE = '#ff4d4d'
export const VERY_LIGHT_TANGELO = '#ffae74'

export const WHITE = '#fff'
export const WHITE_SMOKE = '#f5f5f5'
export const SNOW = '#fafafa'
export const ISABELLINE = '#eee'
export const GAINSBORO = '#dedede'
export const PASTEL_GRAY = '#ccc'
export const GRAY_X11 = '#bbb'
export const QUICK_SILVER = '#a2a2a2'
export const SONIC_SILVER = '#787878'
export const SONIC_SILVER_TRANSPARENT = '#f5f5f573'
export const GRANITE_GRAY = '#666'
export const ARSENIC = '#444'
export const ONYX = '#393939'
export const JET = '#333'
export const CHARLESTON_GREEN = '#282828'
export const CHARLESTON__GREEN = '#2d2d2d'
export const RAISIN_BLACK = '#222121'
export const EERIE_BLACK = '#1e1e1e'
export const DARK_TRANSPARENT = 'rgba(0, 0, 0, 0.4)'


// styles
export const BOX_SHADOW_LIGHT = '0 2px 11px -7px #0000004f'


// media queries
export const sizes = {
  sm: 768,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})
