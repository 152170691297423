import React from 'react'
import { number, bool, string, shape } from 'prop-types'
import styled from 'styled-components'
import { PROP_ASYNC_STATUS } from '../../../constants'
import loaderSvg from '../../../assets/svg/loader.html'
import Svg from '../../Svg/component'

const Wrap = styled.div`
  display: flex;
  ${props => !props.isStatic && `
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`

const LoaderStyled = styled(Svg)`
  ${props => !props.isStatic && `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `};

  path {
    &:nth-child(1) {
      opacity: 1;
      fill: rgba(0, 0, 0, 0.2);
    };
    &:nth-child(2) {
      fill: rgba(0, 0, 0, 0.3);
    };
  }
`

const SpinnerLoader = ({ asyncStatus, width, size, isStatic, className }) => {
  const { isBusy } = asyncStatus

  return (
    isBusy ?
      <Wrap isStatic={isStatic} className={className}>
        <LoaderStyled width={width} size={size} isStatic={isStatic} svg={loaderSvg} />
      </Wrap> : null
    
  )
}

SpinnerLoader.propTypes = {
  isAlwaysVisible: bool,
  asyncStatus: shape(PROP_ASYNC_STATUS),
  hasOverlay: bool,
  size: number,
  width: number,
  isStatic: bool,
  className: string,
}

SpinnerLoader.defaultProps = {
  isAlwaysVisible: false,
  asyncStatus: undefined,
  hasOverlay: false,
  size: 4,
  width: 4,
  isStatic: false,
  className: '',
}

export default SpinnerLoader
