import Component from './component'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDictionaries, updateDictionary, removeDictionaryItem, addDictionary } from './actions'

const mapStateToProps = ({ home }, ownProps) => {
  const {
    dictionaries,
    focusedDictionaryItemId,
    asyncStatusGetDictionaries,
    asyncStatusUpdateDictionaryItem,
    asyncStatusRemoveDictionaryItem,
    asyncStatusAddDictionary,
  } = home
  const { activeDictionaryName = (dictionaries[0] || {}).dictionaryName } = ownProps.match.params

  return {
    dictionaries,
    activeDictionaryName,
    focusedDictionaryItemId,
    asyncStatusGetDictionaries,
    asyncStatusUpdateDictionaryItem,
    asyncStatusRemoveDictionaryItem,
    asyncStatusAddDictionary,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDictionaries() {
    dispatch(getDictionaries())
  },
  onUpdateItem(values) {
    dispatch(updateDictionary(values))
  },
  onRemoveItem(values) {
    dispatch(removeDictionaryItem(values))
  },
  onAddDictionary(values) {
    dispatch(addDictionary(values))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
