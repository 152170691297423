import React from 'react'
import { bool, string, func } from 'prop-types'
import styled from 'styled-components'
import { SIZE_MD, PASTEL_GRAY, WHITE, CYAN } from '../../../styles'

export const CL_CHECKBOX_LABEL = 'checkbox-label'

const Wrap = styled.div`
  display: ${props => props.isHidden ? 'none' : 'flex'};
  align-items: center;
`

const Label = styled.label`
  margin-left: ${SIZE_MD};
  ${props => props.isChecked && `color: ${CYAN}`};
`

const CheckboxWrap = styled.div`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
`

const CheckboxStyled = styled.input`
  display: none;

  & + label {
    opacity: 0.5;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 2px ${PASTEL_GRAY};

    &::before {
      content: '';
      position: absolute;
      margin-top: -1px;
      width: 16px;
      height: 7px;
      box-shadow: inset 4px -4px 0 -2px ${PASTEL_GRAY};
      transform: rotate(-45deg) scale(0.8);
      transition: transform 0.1s ease-out;
    };

    &:hover {
      opacity: 1;
    };
  };

  &:checked {
    & + label {
      opacity: 1;
      background-color: ${CYAN};
      box-shadow: none;

      &::before {
        box-shadow: inset 4px -4px 0 -2px ${WHITE};
        transform: rotate(-45deg) scale(1);
      };
    };
  };
`

const Checkbox = ({ isChecked, label, className, id, onChange }) => (
  <Wrap className={className}>
    <CheckboxWrap>
      <CheckboxStyled
        checked={isChecked}
        type="checkbox"
        id={id}
        onChange={onChange}
      />
      <label htmlFor={id} />
    </CheckboxWrap>
    <Label isChecked={isChecked} htmlFor={id} className={CL_CHECKBOX_LABEL}>{label}</Label>
  </Wrap>
)

Checkbox.propTypes = {
  isChecked: bool,
  label: string,
  className: string,
  id: string,
  onChange: func,
}

Checkbox.defaultProps = {
  isChecked: false,
  label: undefined,
  className: '',
  id: undefined,
  onChange() {},
}

export default Checkbox
