import React from 'react'
import { shape } from 'prop-types'
import styled from 'styled-components'
import { SIZE_MD, SUNSET_ORANGE } from '../../../styles'
import { PROP_ASYNC_STATUS } from '../../../constants'
import errorApiSvg from '../../../assets/svg/error-api.html'
import Svg from '../../Svg/component'

const Wrap = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${SIZE_MD};
  width: 14rem;
  height: 14rem;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: ${SUNSET_ORANGE};
`

const SvgStyled = styled(Svg)`
  display: flex;
  width: 2.4rem;
  height: 100%;
  fill: rgba(255, 255, 255, 0.9);
`

const ErrorPage = ({ asyncStatus: { isError } }) => (
  !isError ? null :
  <Wrap>
    <SvgStyled svg={errorApiSvg} static />
  </Wrap>
)

ErrorPage.propTypes = {
  asyncStatus: shape(PROP_ASYNC_STATUS).isRequired,
}

export default ErrorPage
