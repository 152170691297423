import React from 'react'
import { string, shape } from 'prop-types'
import styled from 'styled-components'
import { SUNSET_ORANGE } from '../../../styles'
import { PROP_ASYNC_STATUS } from '../../../constants'
import infoSvg from '../../../assets/svg/info.html'
import Svg from '../../Svg/component'
import Tooltip from '../../Tooltip/component'

export const CN_ERROR_FLOAT_SVG = 'error-float-svg'

const SvgStyled = styled(Svg)`
  display: flex;
  width: 1.8rem;
  fill: ${SUNSET_ORANGE};
`

const FloatError = ({ asyncStatus, className }) => {
  const { isError, errorMessage } = asyncStatus

  return (
    isError ?
      <Tooltip tooltip={errorMessage} className={className}>
        <SvgStyled svg={infoSvg} static className={CN_ERROR_FLOAT_SVG} />
      </Tooltip> : null
  )
}

FloatError.propTypes = {
  asyncStatus: shape(PROP_ASYNC_STATUS),
  className: string,
}

FloatError.defaultProps = {
  asyncStatus: undefined,
  className: undefined,
}

export default FloatError
