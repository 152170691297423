import React from 'react'
import { string, any } from 'prop-types'
import styled from 'styled-components'
import { SIZE_LG, SIZE_MD, FONT_SIZE_SM, SUNSET_ORANGE, SIZE_SM } from '../../styles'

const CN_TOOLTIP = 'tooltip'

const Wrap = styled.div`
  &:hover {
    .${CN_TOOLTIP} {
      visibility: visible;
      margin-right: ${SIZE_LG};
      transition: margin 0.2s ease-out, visibility 0.4s ease-out;
    };
  };
`
const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  right: 100%;
  top: 50%;
  border-right: 2px solid ${SUNSET_ORANGE};
  margin-right: ${SIZE_MD};
  padding: ${SIZE_SM} ${SIZE_MD};
  line-height: 1.1;
  font-size: ${FONT_SIZE_SM};
  text-transform: uppercase;
  background-color: ${SUNSET_ORANGE};
  color: #fff;
  font-weight: bold;
  border-radius: 2px;
  white-space: nowrap;
  transform: translateY(-50%);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent ${SUNSET_ORANGE};
    width: 0;
    height: 0;
    transform: translateY(-50%);
  };
`

const TooltipWrap = ({ tooltip, children, className }) => (
  <Wrap className={className}>
    {children}
    <Tooltip className={CN_TOOLTIP}>
      {tooltip}
    </Tooltip>
  </Wrap>
)

TooltipWrap.propTypes = {
  tooltip: string.isRequired,
  children: any.isRequired,
  className: string,
}

TooltipWrap.defaultProps = {
  className: undefined,
}

export default TooltipWrap
