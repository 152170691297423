import React from 'react'
import { bool, string, object } from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { SIZE_XSM, SIZE_MD, SUNSET_ORANGE, CYAN, SONIC_SILVER } from '../../../styles'
import ErrorField from '../../Error/Field/component'

import { CLASSNAME_PLACEHOLDER, CLASSNAME_HAS_VALUE, STYLE_BOX_SHADOW_DEFAULT } from '../constants'
import Placeholder, { STYLES_PLACEHOLDER_ACTIVE } from '../Placeholder/component'

export const CN_TEXT_FIELD = 'text-field'

const Wrap = styled.div`
  display: ${props => props.isHidden ? 'none' : 'flex'};
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 0.6rem;
`

const errorStyles = `
  color: ${SUNSET_ORANGE} !important;
  box-shadow: 0 3px 0 -1px ${SUNSET_ORANGE} !important;

  &:focus {
    box-shadow: 0 3px 0 -1px ${SUNSET_ORANGE} !important;
  }
`

const activeStylesTextField = `
  padding: ${SIZE_MD} 0 ${SIZE_XSM};
`

const TextFieldStyled = styled.input`
  display: flex;
  width: 100%;
  height: 3.2rem;
  background-color: transparent;
  box-shadow: ${STYLE_BOX_SHADOW_DEFAULT};
  color: ${SONIC_SILVER};

  &:focus {
    box-shadow: 0 3px 0 -1px ${CYAN};
    ${activeStylesTextField};
  };

  ${props => props.touched && props.error && errorStyles};
  ${props => props.hasValue && activeStylesTextField};

  ${props => props.isDisabled && `
    pointer-events: none;
    color: rgba(255, 255, 255, 0.2);
  `};

  &:focus + ${Placeholder} {
    ${STYLES_PLACEHOLDER_ACTIVE};
  };
`

const TextField = ({  isDisabled, input, placeholder, meta: { touched, error }, id, className }) => {
  const isError = touched && error
  const hasValue = input.value !== ''

  return (
    <Wrap isDisabled={isDisabled} className={className}>
      <TextFieldStyled
        {...input}
        isDisabled={isDisabled}
        hasValue={hasValue}
        type="text"
        touched={touched}
        error={error}
        className={classnames(CN_TEXT_FIELD, { [CLASSNAME_HAS_VALUE]: hasValue })}
        id={id}
        tabIndex={isDisabled ? -1 : 0}
        onChange={input.onChange}
      />
      {placeholder &&
        <Placeholder
          hasValue={hasValue}
          isError={isError}
          isDisabled={isDisabled}
          className={CLASSNAME_PLACEHOLDER}
        >
          {placeholder}
        </Placeholder>
      }
      <ErrorField fieldStatus={{ isError: touched, errorMessage: error }} />
    </Wrap>
  )
}

TextField.propTypes = {
  isDisabled: bool,
  className: string,
  input: object.isRequired,
  placeholder: string,
  afterText: string,
  meta: object.isRequired,
  id: string,
}

TextField.defaultProps = {
  isDisabled: false,
  className: '',
  placeholder: '',
  afterText: undefined,
  id: undefined,
}

export default TextField
