export * from './shapeState'
export * from './shapePropTypes'

const THEME_BREATH = 'breath'
export const THEME_NORMAL = 'normal'
const THEME_COMPACT = 'compact'
export const THEMES = [THEME_BREATH, THEME_NORMAL, THEME_COMPACT]

export const getCase = ((action, status) => `${action}_${status}`)
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAIL = 'FAIL'

export const handleAPIResponse = ({ data: { body }}) => JSON.parse(body)
