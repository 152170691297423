import { ERROR_REQUIRED, ERROR_DUPLICATE, DOMAIN_FIELD, RANGE_FIELD, TYPE_FIELD } from '../constants'

export default () => (values, { dictionaries, initialValues }) => {
  const reservedValues = []
  dictionaries.forEach((dictionary) => {
    reservedValues.push(...dictionary.list.map(({ id, range, domain }) => ({ id, range, domain }) ))
  })
  const reservedValuesFiltered = reservedValues
    .filter(dictionaryItem => dictionaryItem.id !== initialValues.id)
    .map(({ domain, range }) => ([range, domain]))
    .flat()
    .filter(values => values)
  const errors = {}

  if (!values[DOMAIN_FIELD]) {
    errors[DOMAIN_FIELD] = ERROR_REQUIRED
  }

  if (values[DOMAIN_FIELD] && reservedValuesFiltered.includes(values[DOMAIN_FIELD])) {
    errors[DOMAIN_FIELD] = ERROR_DUPLICATE
  }

  if (!values[RANGE_FIELD]) {
    errors[RANGE_FIELD] = ERROR_REQUIRED
  }

  if (values[RANGE_FIELD] && reservedValuesFiltered.includes(values[RANGE_FIELD])) {
    errors[RANGE_FIELD] = ERROR_DUPLICATE
  }

  if (!values[TYPE_FIELD]) {
    errors[TYPE_FIELD] = ERROR_REQUIRED
  }

  return errors
}
