export const objectRemoveEmptyStrings = (object) => {
  const newObj = {}
  Object.keys(object).forEach((key) => {
    if (object[key] !== '') {
      newObj[key] = object[key]
    }
  })
  return newObj
}

export const isValidDictionary = object => (
  object.domain !== undefined && object.dataType !== undefined && object.range !== undefined
)
