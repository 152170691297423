import axios from 'axios'
import { reset } from 'redux-form'
import {
  apiGetDictionaries,
  apiUpdateDictionary,
  apiAddDictionaryItem,
  apiRemoveDictionary,
  apiAddDictionary,
} from '../../api'
import { handleAPIResponse } from '../../constants'
import uuid from '../../utils/uuid'
import { objectRemoveEmptyStrings, isValidDictionary } from '../../utils'

export const GET_DICTIONARIES = 'GET_DICTIONARIES'
export const getDictionaries = () => {
  const url = apiGetDictionaries()
  return {
    type: GET_DICTIONARIES,
    payload: {
      promise: axios.get(url).then(handleAPIResponse),
    }
  }
}

export const UPDATE_DICTIONARY = 'UPDATE_DICTIONARY'
export const updateDictionary = (params) => {
  const {
    isNew,
    dictionaryName,
    dictionaryGroup,
    id,
    domain,
    dataType,
    range,
    description,
    fieldToUpdate,
    fieldValue,
  } = params
  const url = isNew ? apiAddDictionaryItem() : apiUpdateDictionary(id)
  const requestMethod = isNew ? 'post' : 'put'
  const paramsFiltered = objectRemoveEmptyStrings({
    id,
    dictionaryName,
    dictionaryGroup,
    domain,
    dataType,
    range,
    description,
    [fieldToUpdate]: fieldValue,
  })

  return {
    type: UPDATE_DICTIONARY,
    payload: {
      promise: !isValidDictionary(paramsFiltered) ? params : axios[requestMethod](url, paramsFiltered).then(() => params),
    },
    meta: params,
  }
}

export const REMOVE_DICTIONARY_ITEM = 'REMOVE_DICTIONARY_ITEM'
export const removeDictionaryItem = (params) => {
  const url = apiRemoveDictionary(params.id)
  return {
    type: REMOVE_DICTIONARY_ITEM,
    payload: {
      promise: axios.delete(url).then(() => params),
    },
    meta: params,
  }
}

export const ADD_DICTIONARY = 'ADD_DICTIONARY'
export const addDictionary = ({ dictionaryName, newGroupName, existingGroupName }) => (dispatch) => {
  const paramsMapped = {
    id: uuid(),
    dictionaryName,
    dictionaryGroup: newGroupName || existingGroupName,
  }
  const url = apiAddDictionary()
  return dispatch({
    type: ADD_DICTIONARY,
    payload: {
      promise: axios.post(url, paramsMapped).then(() => paramsMapped),
    }
  }).then(() => dispatch(reset('add-new-dictionary')))
}
