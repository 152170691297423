import React from 'react'
import { string, shape, arrayOf, func } from 'prop-types'
import styled from 'styled-components'
import { WHITE_SMOKE, SIZE_MD, SONIC_SILVER_TRANSPARENT, SIZE_LG, CYAN, SIZE_XSM, SIZE_SM, FONT_SIZE_LG } from '../../../styles'
import { PROP_ASYNC_STATUS } from '../../../constants'

import DictionaryRF from '../DictionaryForm/component'

const Wrap = styled.div`
  width: 100%;
  line-height: normal;
`
const Header = styled.div`
  padding: 0 ${SIZE_LG};
  margin-bottom: ${SIZE_LG};
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${FONT_SIZE_LG};
  color: ${CYAN};
`

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${WHITE_SMOKE};
  border-top: 1px solid ${WHITE_SMOKE};
  padding: ${SIZE_MD};
  padding-right: 4.8rem;
  text-transform: uppercase;
  background-color: ${SONIC_SILVER_TRANSPARENT};
  user-events: none;
  font-weight: bold;

`
const Cell = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding: ${SIZE_XSM} 0;
  margin: 0 0.6rem;
`

const Dictionary = ({
  activeDictionary: { dictionaryName, list = [] },
  dictionaries,
  focusedDictionaryItemId,
  onUpdateItem,
  onRemoveItem,
  className,
  asyncStatusUpdateDictionaryItem,
  asyncStatusRemoveDictionaryItem,
}) => {
  return (
    <Wrap className={className}>
      <Header>{dictionaryName}</Header>
      <Row>
        <Cell>Domain</Cell>
        <Cell>Range</Cell>
        <Cell>Data type</Cell>
        <Cell>Description</Cell>
      </Row>
      {list.map((dictionaryItem) => {
        const key=`${dictionaryName}-${dictionaryItem.id}`
        return (
          <DictionaryRF
            key={key}
            form={key}
            dictionaryItem={dictionaryItem}
            initialValues={dictionaryItem}
            dictionaries={dictionaries}
            focusedDictionaryItemId={focusedDictionaryItemId}
            onUpdateItem={onUpdateItem}
            onRemoveItem={onRemoveItem}
            asyncStatusUpdateDictionaryItem={asyncStatusUpdateDictionaryItem}
            asyncStatusRemoveDictionaryItem={asyncStatusRemoveDictionaryItem}
          />
        )
      })}
    </Wrap>
  )
}

Dictionary.propTypes = {
  dictionaries: arrayOf(shape({})).isRequired,
  activeDictionary: shape({}),
  className: string,
  focusedDictionaryItemId: string,
  onUpdateItem: func.isRequired,
  onRemoveItem: func.isRequired,
  asyncStatusUpdateDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
  asyncStatusRemoveDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
}

Dictionary.defaultProps = {
  activeDictionary: {},
  focusedDictionaryItemId: undefined,
  className: undefined,
}

export default Dictionary
