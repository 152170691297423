import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { withRouter } from 'react-router-dom'

import Component from './component'
import { homePathSegment } from '../Root/paths'

const mapStateToProps = (({ home }, ownProps) => {
  const matchPathObject = matchPath(ownProps.location.pathname, { path: homePathSegment() })
  const { dictionaries } = home
  const activeDictionaryName = matchPathObject.params.activeDictionaryName || (dictionaries[0] || {}).dictionaryName

  return {
    dictionaries,
    activeDictionaryName,
  }
})

export default withRouter(connect(mapStateToProps)(Component))
