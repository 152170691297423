import React, { useState } from 'react'
import { string, number, func, oneOfType, arrayOf, bool } from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components'
import chevronDownSvg from '../../../assets/svg/chevron-down.html'
import { SIZE_XSM, SIZE_MD, SIZE_LG, SONIC_SILVER, VERY_LIGHT_TANGELO } from '../../../styles'
import Svg from '../../Svg/component'
import { ErrorField } from '../../Error'

import { TextField } from '../index'
import { STYLE_BOX_SHADOW_DEFAULT } from '../constants'
import { Placeholder } from '../index'

export const CN_DROPDOWN_HEADER = 'dropdown-header'
export const CN_DROPDOWN_CHEVRON = 'dropdown-chevron'
export const CN_DROPDOWN_MENU = 'dropdown-menu'

const STYLE_LABEL_OPEN_COLOR = '#ffffffc9'
const STYLE_LABEL_OPEN_SVG_COLOR = '#ffffffc9'
const STYLE_PLACEHOLDER_ACTIVE = '#ffffff73'
const STYLE_COLOR = '#ffffff99'
const STYLE_COLOR_ACTIVE = '#ffffffcf'
const STYLE_COLOR_HOVER = '#ffffffcf'
const STYLE_BG = '#3c5677'

const DropdownWrap = styled.div`
  z-index: 1;
  position: relative;
  outline: none;
  ${props => props.isOpen && `background-color: ${STYLE_BG}`};
  ${props => props.isDisabled && 'pointer-events: none'};
`
const HeaderWrap = styled.div`
  padding: ${SIZE_MD} ${SIZE_LG};
  fill: ${SONIC_SILVER};
  cursor: pointer;
`
const HeaderInnerWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  box-shadow: ${props => props.isOpen ? '0px 2px 0 -1px #ffffff40' : STYLE_BOX_SHADOW_DEFAULT};

  ${props => props.hasActiveItem && `
    padding: ${SIZE_MD} 0 ${SIZE_XSM};
    color: ${SONIC_SILVER};
  `};

  ${props => props.isInfo && `
    box-shadow: 0 2px 0 -1px ${VERY_LIGHT_TANGELO};
    color: ${VERY_LIGHT_TANGELO};
  `};
`
const Label = styled.div`
  ${props => props.isOpen && `
    color: ${STYLE_LABEL_OPEN_COLOR};
  `};
`
const PlaceholderStyled = styled(Placeholder)`
  ${props => props.isOpen && `color: ${STYLE_PLACEHOLDER_ACTIVE}`}
`
const ChevronDownSvg = styled(Svg)`
  display: flex;
  margin-left: auto;
  width: 0.9rem;
  height: 0.9rem;

  svg {
    fill: ${props => props.isOpen ? STYLE_LABEL_OPEN_SVG_COLOR : 'inherit'};
  };
`
const Menu = styled.ul`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  padding: ${SIZE_MD} ${SIZE_LG} ${SIZE_LG};
  width: 100%;
  color: ${STYLE_COLOR};
  background-color: ${STYLE_BG};
`
const FieldStyled = styled(TextField)`
  display: none;
`

const Item = styled.li`
  margin-top: ${SIZE_MD};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: ${props => props.isActive ? STYLE_COLOR_ACTIVE : STYLE_COLOR_HOVER};
  };
  
  &:first-child {
    margin-top: 0;
  };

  ${props => props.isActive && `
    color: ${STYLE_COLOR_ACTIVE};
    font-weight: bold;
  `};
`

const Dropdown = ({ isInfo, infoMessage, activeItem, placeholder, fieldName, list, className, onItemSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasActiveItem = activeItem !== undefined
  const onItemSelectHandler = (item) => {
    setIsOpen(false)
    onItemSelect(item)
  }

  return (
    <DropdownWrap isDisabled={!list.length} isOpen={isOpen} className={className}>
      <HeaderWrap onClick={() => setIsOpen(!isOpen)} className={CN_DROPDOWN_HEADER}>
        <HeaderInnerWrap isInfo={isInfo} isOpen={isOpen} hasActiveItem={hasActiveItem}>
          <PlaceholderStyled
            isOpen={isOpen}
            isInfo={isInfo}
            hasValue={hasActiveItem}
          >
            {placeholder}
          </PlaceholderStyled>
          <Label isOpen={isOpen}>{activeItem}</Label>
          <ChevronDownSvg
            isHidden={isInfo}
            className={CN_DROPDOWN_CHEVRON}
            isOpen={isOpen}
            svg={chevronDownSvg}
          />
          <ErrorField
            isInfoVariant={isInfo}
            infoMessage={infoMessage}
          />
        </HeaderInnerWrap>
      </HeaderWrap>
      {isOpen && (
        <>
          {fieldName && <Field name={fieldName} id={fieldName} value={activeItem} component={FieldStyled} />}
          <Menu isOpen={isOpen} isActive={hasActiveItem} className={CN_DROPDOWN_MENU}>
            {list.map(item => (
              <Item isActive={activeItem === item} onClick={() => onItemSelectHandler(item)} key={item}>{item}</Item>
            ))}
          </Menu>
        </>
      )}
    </DropdownWrap>
  )
}

Dropdown.propTypes = {
  isInfo: bool,
  infoMessage: string, 
  placeholder: string,
  activeItem: oneOfType([string, number]),
  fieldName: string,
  list: arrayOf(string).isRequired,
  onItemSelect: func.isRequired,
  className: string,
}

Dropdown.defaultProps = {
  isInfo: false,
  infoMessage: undefined,
  placeholder: undefined,
  activeItem: undefined,
  fieldName: undefined,
  className: undefined,
}

export default Dropdown
