import uuid from '../../utils/uuid'
import {
  getCase,
  REQUEST,
  SUCCESS,
  FAIL,
  SHAPE_ASYNC_STATUS_INITIAL,
  SHAPE_ASYNC_STATUS_REQUESTED,
  SHAPE_ASYNC_STATUS_SUCCEEDED,
  SHAPE_ASYNC_STATUS_FAILED,
} from '../../constants'
import { isValidDictionary } from '../../utils'
import {
  GET_DICTIONARIES,
  UPDATE_DICTIONARY,
  REMOVE_DICTIONARY_ITEM,
  ADD_DICTIONARY,
} from './actions'

const generateBlankDictionary = ({ dictionaryName, dictionaryGroup }) => {
  return {
    isNew: true,
    isBlank: true,
    dictionaryName,
    dictionaryGroup,
    id: uuid(),
    domain: '',
    range: '',
    dataType: '',
    description: '',
  }
}

const INITIAL_STATE = {
  dictionaries: [],
  focusedDictionaryItemId: undefined,
  asyncStatusGetDictionaries: SHAPE_ASYNC_STATUS_INITIAL,
  asyncStatusUpdateDictionaryItem: SHAPE_ASYNC_STATUS_INITIAL,
  asyncStatusRemoveDictionaryItem: SHAPE_ASYNC_STATUS_INITIAL,
  asyncStatusAddDictionary: SHAPE_ASYNC_STATUS_INITIAL,
}

const filterOutBlanksFromModal = (({ list, ...rest }) => isValidDictionary(rest))

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCase(GET_DICTIONARIES, REQUEST): {
      return {
        ...state,
        asyncStatusGetDictionaries: SHAPE_ASYNC_STATUS_REQUESTED,
      }
    }
    case getCase(GET_DICTIONARIES, SUCCESS): {
      const dictionaryGrouping = {}

      action.payload.forEach(({ dictionaryName, dictionaryGroup, ...rest }) => {
        dictionaryGrouping[dictionaryName] = {
          ...dictionaryGrouping[dictionaryName],
          dictionaryName,
          dictionaryGroup,
          list: [
            ((dictionaryGrouping[dictionaryName] || {}).list) || [],
            { dictionaryName, dictionaryGroup, ...rest }
          ]
            .flat()
            .filter(filterOutBlanksFromModal)
        }
      })
      const dictionaries = Object.keys(dictionaryGrouping).map(key => dictionaryGrouping[key])
      dictionaries.forEach(({ list, ...rest }) => list.push(generateBlankDictionary(rest)))
      return {
        ...state,
        dictionaries,
        asyncStatusGetDictionaries: SHAPE_ASYNC_STATUS_SUCCEEDED,
      }
    }
    case getCase(GET_DICTIONARIES, FAIL): {
      return {
        ...state,
        asyncStatusGetDictionaries: SHAPE_ASYNC_STATUS_FAILED('failed')
      }
    }
    case UPDATE_DICTIONARY: {
      const { isNew, isBlank, id, dictionaryName, dictionaryGroup, fieldToUpdate, fieldValue } = action.payload.promise
      const dictionaries = [...state.dictionaries]
      const dictionaryToUpdate = dictionaries.find(dictionary => dictionary.dictionaryName === dictionaryName)
      dictionaryToUpdate.list.find(item => item.id === id)[fieldToUpdate] = fieldValue
      dictionaryToUpdate.list.find(item => item.id === id).isBlank = false
      

      if (isNew && isBlank) {
        dictionaryToUpdate.list.push(generateBlankDictionary({ dictionaryName, dictionaryGroup }))
      }

      return {
        ...state,
        dictionaries,
      }
    }
    case getCase(UPDATE_DICTIONARY, REQUEST): {
      return {
        ...state,
        focusedDictionaryItemId: action.meta.id,
        asyncStatusUpdateDictionaryItem: SHAPE_ASYNC_STATUS_REQUESTED,
      }
    }
    case getCase(UPDATE_DICTIONARY, SUCCESS): {
      const { id, dictionaryName } = action.payload
      const dictionaries = [...state.dictionaries]
      const dictionaryToUpdate = dictionaries.find(dictionary => dictionary.dictionaryName === dictionaryName)
      dictionaryToUpdate.list.find(item => item.id === id).isNew = false

      return {
        ...state,
        focusedDictionaryItemId: undefined,
        asyncStatusUpdateDictionaryItem: SHAPE_ASYNC_STATUS_SUCCEEDED
      }
    }
    case getCase(UPDATE_DICTIONARY, FAIL): {
      return {
        ...state,
        focusedDictionaryItemId: action.payload.id,
        asyncStatusUpdateDictionaryItem: SHAPE_ASYNC_STATUS_FAILED('could not update dictionary'),
      }
    }
    case getCase(REMOVE_DICTIONARY_ITEM, REQUEST): {
      return {
        ...state,
        focusedDictionaryItemId: action.meta.id,
        asyncStatusRemoveDictionaryItem: SHAPE_ASYNC_STATUS_REQUESTED,
      }
    }
    case getCase(REMOVE_DICTIONARY_ITEM, SUCCESS): {
      const { id, dictionaryName } = action.payload
      const dictionaries = [...state.dictionaries]
      const dictionaryToUpdate = dictionaries.find(dictionary => dictionary.dictionaryName === dictionaryName)
      dictionaryToUpdate.list = dictionaryToUpdate.list.filter(item => item.id !== id)

      return {
        ...state,
        dictionaries,
        focusedDictionaryItemId: undefined,
        asyncStatusRemoveDictionaryItem: SHAPE_ASYNC_STATUS_SUCCEEDED,
      }
    }
    case getCase(REMOVE_DICTIONARY_ITEM, FAIL): {
      return {
        ...state,
        focusedDictionaryItemId: action.payload.id,
        asyncStatusRemoveDictionaryItem: SHAPE_ASYNC_STATUS_FAILED('could not remove dictionary'),
      }
    }

    case getCase(ADD_DICTIONARY, REQUEST): {
      return {
        ...state,
        asyncStatusAddDictionary: SHAPE_ASYNC_STATUS_REQUESTED,
      }
    }
    case getCase(ADD_DICTIONARY, SUCCESS): {
      const { dictionaryName, dictionaryGroup } = action.payload
      const dictionaries = [
        ...state.dictionaries,
        {
          dictionaryName,
          dictionaryGroup,
          list: [generateBlankDictionary({ dictionaryName, dictionaryGroup })]
        }
      ]
      return {
        ...state,
        dictionaries,
        asyncStatusAddDictionary: SHAPE_ASYNC_STATUS_SUCCEEDED,
      }
    }
    case getCase(ADD_DICTIONARY, FAIL): {
      return {
        ...state,
        asyncStatusAddDictionary: SHAPE_ASYNC_STATUS_FAILED('could not add dictionary'),
      }
    }

    default: {
      return state
    }
  }
}
