import { ERROR_DUPLICATE, ERROR_REQUIRED, NAME_FIELD } from '../constants'

export default () => (values, { dictionaries }) => {
  const reservedDictionaryNames = [...new Set(dictionaries.map(dictionary => dictionary.dictionaryName))]
  const errors = {}

  if (!values[NAME_FIELD]) {
    errors[NAME_FIELD] = ERROR_REQUIRED
  }

  if (values[NAME_FIELD] && reservedDictionaryNames.includes(values[NAME_FIELD])) {
    errors[NAME_FIELD] = ERROR_DUPLICATE
  }

  return errors
}
