import React from 'react'
import { string, func, node } from 'prop-types'
import styled from 'styled-components'
import { media, CYAN, WHITE, BOX_SHADOW_LIGHT, SIZE_SM } from '../../../styles'
import addSvg from '../../../assets/svg/add.html'

import Svg from '../../Svg/component'

const Wrap = styled.button`
  display: flex;
  position: absolute;
  right: ${SIZE_SM};
  bottom: ${SIZE_SM};
  justify-content: center;
  align-items: center;
  border: 2px solid ${WHITE};
  width: 7.2rem;
  height: 7.2rem;
  background-color: ${CYAN};
  border-radius: 50%;
  box-shadow: ${BOX_SHADOW_LIGHT};
  cursor: pointer;
  transition: right 0.1s ease-out, bottom 0.1s ease-out, transform 0.1s ease-out;

  ${media.sm`
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
  `};
`

const SvgStyled = styled(Svg)`
  svg {
    fill: ${WHITE};
  };
`

const ButtonFloating = ({ className, children, onClick }) => (
  <Wrap
    className={className}
    onClick={onClick}
  >
    {children || <SvgStyled svg={addSvg} size={1.6} />}
  </Wrap>
)

ButtonFloating.propTypes = {
  className: string,
  children: node,
  onClick: func,
}

ButtonFloating.defaultProps = {
  onClick() {},
  children: undefined,
  className: '',
}

export default ButtonFloating
