import React from 'react'
import styled from 'styled-components'
import { string, func } from 'prop-types'
import { WHITE, BOX_SHADOW_LIGHT, SIZE_LG, SIZE_MD, SIZE_SM, WHITE_SMOKE } from '../../styles'
import logoImg from '../../assets/logo.png'
import { THEMES } from '../../constants'
import { Dropdown, CN_DROPDOWN_CHEVRON } from '../../components/form'

const Github = styled.a`
  margin-left: ${SIZE_MD};
  padding: ${SIZE_SM} ${SIZE_MD};
  background-color: ${WHITE_SMOKE};
  border-radius: 2px;
`
const Wrap = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  padding: ${SIZE_LG};
  background-color: ${WHITE};
  box-shadow: ${BOX_SHADOW_LIGHT};
`
const LogoLink = styled.a`
  width: 3.2rem;
  height: 3.2rem;
`
const Logo = styled.img`
  width: 100%;
  height: 100%;
`
const DropdownStyled = styled(Dropdown)`
  margin-left: auto;

  .${CN_DROPDOWN_CHEVRON} {
    margin-left: ${SIZE_MD};
  };
`

const Header = ({ activeTheme, setActiveTheme }) => {
  return (
    <Wrap>
      <LogoLink href="https://www.onedot.com" target="_blank" rel="noopener noreferrer">
        <Logo src={logoImg} />
      </LogoLink>
      <DropdownStyled
        isCloseOnSelect
        list={THEMES}
        activeItem={activeTheme}
        placeholder="Theme"
        onItemSelect={selectedTheme => setActiveTheme(selectedTheme)}
      />
      <Github href="https://github.com/vincentbollaert/dictionary-management" target="_blank" rel="noopener noreferrer">
        GitHub
      </Github>
    </Wrap>
  )
}

Header.propTypes = {
  activeTheme: string.isRequired,
  setActiveTheme: func.isRequired,
}

export default Header
