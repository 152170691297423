import React, { useState } from 'react'
import { string, any } from 'prop-types'
import styled from 'styled-components'
import chevronDown from '../../assets/svg/chevron-down.html'
import { ISABELLINE, GRAY_X11, PASTEL_GRAY, SIZE_MD } from '../../styles'
import Svg from '../Svg/component'

const CN_SVG = 'svg'
export const CN_ACCORDION_HEADER = 'accordion-header'

const Wrap = styled.div`
  position: relative;
  margin-left: auto;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => props.isOpen ? ISABELLINE : PASTEL_GRAY};

  &:hover {
    color: ${ISABELLINE};

    .${CN_SVG} {
      fill: ${ISABELLINE};
    };
  };
`
const Name = styled.div``
const AccordionToggle = styled(Svg)`
  margin-left: auto;
  fill: ${props => props.isOpen ? ISABELLINE : GRAY_X11};
  cursor: pointer;

  &:active {
    fill: ${ISABELLINE};
  };
`
const Body = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  margin-bottom: ${SIZE_MD};
  margin-left: ${SIZE_MD};
`

const Accordion = ({ name, content, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Wrap className={className}>
      <Header isOpen={isOpen} className={CN_ACCORDION_HEADER} onClick={() => setIsOpen(!isOpen)}>
        <Name>{name}</Name>
        <AccordionToggle
          isOpen={isOpen}
          svg={chevronDown}
          size={1.2}
          className={CN_SVG}
        />
      </Header>
      <Body isOpen={isOpen}>
        {content}
      </Body>
    </Wrap>
  )
}

Accordion.propTypes = {
  name: string.isRequired,
  content: any.isRequired,
  className: string,
}

Accordion.defaultProps = {
  className: '',
}

export default Accordion
