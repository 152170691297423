import { connect } from 'react-redux'

import Component from './component'

const mapStateToProps = (({ home }) => {
  const { dictionaries } = home

  return {
    dictionaries
  }
})

export default connect(mapStateToProps)(Component)
