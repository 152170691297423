import React, { useState, useEffect } from 'react'
import { shape, arrayOf, string, func } from 'prop-types'
import { PROP_ASYNC_STATUS } from '../../constants'
import Dictionary from './Dictionary/component'
import ButtonFloating from '../../components/Button/Floating/component'
import Modal from '../../components/Modal/component'
import { SpinnerLoader } from '../../components/Loader'
import { ErrorPage } from '../../components/Error'

import { EXISTING_GROUP_NAME_FIELD } from './constants'
import AddNewDictionaryRF from './AddNewDictionaryForm/component'

const Home = ({
  dictionaries,
  activeDictionaryName,
  focusedDictionaryItemId,
  onGetDictionaries,
  onAddDictionary,
  onUpdateItem,
  onRemoveItem,
  asyncStatusGetDictionaries,
  asyncStatusUpdateDictionaryItem,
  asyncStatusRemoveDictionaryItem,
  asyncStatusAddDictionary,
}) => {
  useEffect(() => { onGetDictionaries() }, [])
  const [isModalVisible, setModalVisibility] = useState(false)
  const [selectedDictionaryGroup, selectDictionaryGroup] = useState(undefined)
  const activeDictionary = (dictionaries.find(({ dictionaryName }) => dictionaryName === activeDictionaryName) || {})

  return (
    <div>
      <ErrorPage asyncStatus={asyncStatusGetDictionaries} />
      <SpinnerLoader asyncStatus={asyncStatusGetDictionaries} size={6} />
      <Dictionary
        key={activeDictionary.dictionaryName}
        activeDictionary={activeDictionary}
        dictionaries={dictionaries}
        focusedDictionaryItemId={focusedDictionaryItemId}
        onUpdateItem={onUpdateItem}
        onRemoveItem={onRemoveItem}
        asyncStatusUpdateDictionaryItem={asyncStatusUpdateDictionaryItem}
        asyncStatusRemoveDictionaryItem={asyncStatusRemoveDictionaryItem}
      />
      <ButtonFloating onClick={() => setModalVisibility(!isModalVisible)} />
      <Modal
        isVisible={isModalVisible}
        title='Add new dictionary'
        onOverlayToggle={() => setModalVisibility(!isModalVisible)}
      >
        <AddNewDictionaryRF
          initialValues={{ [EXISTING_GROUP_NAME_FIELD]: selectedDictionaryGroup }}
          dictionaries={dictionaries}
          selectedDictionaryGroup={selectedDictionaryGroup}
          onSelectDictionaryGroup={selectDictionaryGroup}
          form='add-new-dictionary'
          onSubmit={onAddDictionary}
          asyncStatusAddDictionary={asyncStatusAddDictionary}
        />
      </Modal>
    </div>
  )
}

Home.propTypes = {
  dictionaries: arrayOf(shape({})).isRequired,
  activeDictionaryName: string,
  focusedDictionaryItemId: string,
  onGetDictionaries: func.isRequired,
  onAddDictionary: func.isRequired,
  onUpdateItem: func.isRequired,
  onRemoveItem: func.isRequired,
  asyncStatusGetDictionaries: shape(PROP_ASYNC_STATUS).isRequired,
  asyncStatusUpdateDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
  asyncStatusRemoveDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
  asyncStatusAddDictionary: shape(PROP_ASYNC_STATUS).isRequired,
}

Home.defaultProps = {
  activeDictionaryName: undefined,
  focusedDictionaryItemId: undefined,
}

export default Home
