import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import reduxThunk from 'redux-thunk'
import { createPromise } from 'redux-promise-middleware'

import { REQUEST, SUCCESS, FAIL } from '../../constants'
import Application from '../component'
import reducers from './reducers'

const Root = () => {
  const history = createBrowserHistory()
  const store = createStore(
    reducers,
    undefined,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        reduxThunk,
        createPromise({
          promiseTypeSuffixes: [REQUEST, SUCCESS, FAIL]
        })
      ),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  )

  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={props => <Application {...props} />}
          />
        </Switch>
      </Router>
    </Provider>
  )
}

export default Root
