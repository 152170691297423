import React from 'react'
import { bool, func, shape, arrayOf, string } from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import styled from 'styled-components'
import { debounce } from '../../../utils/lodash'
import closeSvg from '../../../assets/svg/close.html'
import Svg from '../../../components/Svg/component'
import { TextField } from '../../../components/form'
import Button from '../../../components/Button/component'
import { SHAPE_ASYNC_STATUS_INITIAL, PROP_ASYNC_STATUS } from '../../../constants'
import determineAsyncStatus from '../../../utils/determineAsyncStatus'

import { DOMAIN_FIELD, RANGE_FIELD, TYPE_FIELD, DESCRIPTION_FIELD } from '../constants'
import validateFields from './validation'
import { SUNSET_ORANGE, SIZE_MD, SIZE_SM, SIZE_LG } from '../../../styles'

const Form = styled.form`
  display: flex;
  margin-top: ${SIZE_LG};
  padding: 0 ${SIZE_MD};
`

const ButtonStyled = styled(Button)`
  margin-left: ${SIZE_SM};
  flex-basis: 3.2rem;
`
const Icon = styled(Svg)`
  display: ${props => props.isBusy ? 'none' : 'flex'};
  padding: ${SIZE_SM} 1rem;
  height: 100%;
  flex-basis: 3.2rem;
  cursor: pointer;

  svg {
    fill: ${SUNSET_ORANGE};
    opacity: 0.7;

    &:hover {
      opacity: 1;
    };
  };
`

const DictionaryForm = (props) => {
  const {
    valid,
    dictionaryItem,
    focusedDictionaryItemId,
    onUpdateItem,
    onRemoveItem,
    asyncStatusUpdateDictionaryItem,
    asyncStatusRemoveDictionaryItem,
  } = props
  const { id, dictionaryName } = dictionaryItem
  
  const onUpdateHandler = (e) => {
    onUpdateItem({
      ...dictionaryItem,
      fieldToUpdate: e.target.name,
      fieldValue: e.target.defaultValue,
    })
  }
    
  const onUpdateDebounced = debounce(200)(onUpdateHandler)
  const onRemoveHandler = () => onRemoveItem({ id: id, dictionaryName })
  const asyncStatusFocus = determineAsyncStatus([asyncStatusRemoveDictionaryItem, asyncStatusUpdateDictionaryItem])

  return (
    <Form>
      <Field
        name={DOMAIN_FIELD}
        id={DOMAIN_FIELD}
        placeholder="domain"
        component={TextField}
        onChange={onUpdateDebounced}
      />
      <Field
        name={RANGE_FIELD}
        id={RANGE_FIELD}
        placeholder="range"
        component={TextField}
        onChange={onUpdateDebounced}
      />
      <Field
        name={TYPE_FIELD}
        id={TYPE_FIELD}
        placeholder="type"
        component={TextField}
        onChange={onUpdateDebounced}
      />
      <Field
        name={DESCRIPTION_FIELD}
        id={DESCRIPTION_FIELD}
        placeholder="description"
        component={TextField}
        onChange={onUpdateDebounced}
      />
      <ButtonStyled
        isDisabled={!valid}
        onClick={onRemoveHandler}
        asyncStatus={id === focusedDictionaryItemId ? asyncStatusFocus : SHAPE_ASYNC_STATUS_INITIAL}
      >
        <Icon svg={closeSvg} />
      </ButtonStyled>
    </Form>
  )
}

DictionaryForm.propTypes = {
  valid: bool.isRequired,
  initialValues: shape({}).isRequired,
  dictionaryItem: shape({}).isRequired,
  dictionaries: arrayOf(shape({})).isRequired,
  focusedDictionaryItemId: string,
  onUpdateItem: func.isRequired,
  onRemoveItem: func.isRequired,
  asyncStatusUpdateDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
  asyncStatusRemoveDictionaryItem: shape(PROP_ASYNC_STATUS).isRequired,
}

DictionaryForm.defaultProps = {
  focusedDictionaryItemId: undefined,
}

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validateFields([DOMAIN_FIELD, RANGE_FIELD, TYPE_FIELD], this),
})(DictionaryForm)
