import React, { useState } from 'react'
import { bool, func, arrayOf, shape, string } from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import styled from 'styled-components'
import { SIZE_LG, SIZE_XLG, GRAY_X11, SIZE_MD } from '../../../styles'
import { PROP_ASYNC_STATUS } from '../../../constants'
import { TextField, Checkbox, Dropdown, CN_DROPDOWN_HEADER, CN_DROPDOWN_MENU } from '../../../components/form'
import Button from '../../../components/Button/component'

import { NAME_FIELD, NEW_GROUP_NAME_FIELD, EXISTING_GROUP_NAME_FIELD } from '../constants'
import validateFields from './validation'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const TextFieldStyled = styled(TextField)`
  margin: ${SIZE_MD} ${SIZE_XLG} 0;
  padding: 0;
`
const GroupsWrap = styled.div`
  margin-top: ${SIZE_LG};
  padding: ${SIZE_MD} 0;
`
const CheckboxStyled = styled(Checkbox)`
  margin: 0 ${SIZE_XLG};
`
const DropdownStyled = styled(Dropdown)`
  .${CN_DROPDOWN_HEADER} {
    padding: ${SIZE_MD} ${SIZE_XLG};
  };

  .${CN_DROPDOWN_MENU} {
    padding-right: ${SIZE_XLG};
    padding-left: ${SIZE_XLG};
  };
`
const Footer = styled.div`
  display: flex;
  margin-top: ${SIZE_LG};
  padding: 0 ${SIZE_XLG};
  text-transform: uppercase;
  color: ${GRAY_X11};
`

const DictionaryForm = ({
  dictionaries,
  selectedDictionaryGroup,
  onSelectDictionaryGroup,
  valid,
  handleSubmit,
  asyncStatusAddDictionary,
}) => {
  const [isGroup, setIsGroup] = useState(false)
  const groupNames = [...new Set(dictionaries.map(dictionary => dictionary.dictionaryGroup))].filter(group => group)

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name={NAME_FIELD}
        id={NAME_FIELD}
        placeholder="name"
        component={TextFieldStyled}
      />
      <GroupsWrap>
        <CheckboxStyled
          id="add-to-category"
          label="add to category"
          isChecked={isGroup}
          onChange={() => setIsGroup(!isGroup)}
        />
        {isGroup && (
          <div>
            <Field
              isDisabled={selectedDictionaryGroup !== undefined}
              name={NEW_GROUP_NAME_FIELD}
              id={NEW_GROUP_NAME_FIELD}
              placeholder="new category"
              component={TextFieldStyled}
            />
            <DropdownStyled
              activeItem={selectedDictionaryGroup}
              placeholder="existing categories"
              list={groupNames}
              isInfo={!groupNames.length}
              infoMessage="No categories"
              fieldName={EXISTING_GROUP_NAME_FIELD}
              onItemSelect={group => onSelectDictionaryGroup(group)}
            />
          </div>
        )}
      </GroupsWrap>

      <Footer>
        <Button isStyled type="submit" asyncStatus={asyncStatusAddDictionary} isDisabled={!valid}>
          Add
        </Button>
      </Footer>
    </Form>
  )
}

DictionaryForm.propTypes = {
  dictionaries: arrayOf(shape({})).isRequired,
  valid: bool.isRequired,
  selectedDictionaryGroup: string,
  onSelectDictionaryGroup: func.isRequired,
  handleSubmit: func.isRequired,
  asyncStatusAddDictionary: shape(PROP_ASYNC_STATUS).isRequired,
}

DictionaryForm.defaultProps = {
  selectedDictionaryGroup: undefined,
}

export default reduxForm({
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  validate: validateFields([NAME_FIELD], this),
})(DictionaryForm)
